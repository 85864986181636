import React, { useEffect, useCallback } from 'react';
import {
  Avatar,
  Button,
  Flex,
  Layout,
  Menu,
  notification,
  theme,
  Typography,
} from 'antd';

import { ReactComponent as WhiteLogo } from 'assets/icons/WhiteLogo.svg';
import { ReactComponent as Person } from 'assets/icons/Person.svg';
import { useAuthContext } from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { matchPath, useHistory } from 'react-router';
import navigationConfig, {
  MenuItem,
  navigationMenus,
} from 'routes/navigationConfig';
import BaseService from 'services/api/BaseService';

const { Content, Sider } = Layout;

const AppLayout = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const { auth, logout } = useAuthContext();
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const history = useHistory();
  const pathname = history.location.pathname;
  const handleLogout = useCallback(() => {
    logout();
    history.push('/connexion');
  }, [history, logout]);

  const handleMenuClick = (e: MenuItem) => {
    if (typeof e?.key === 'string') history.push(e.key);
  };

  const visibilityChange = () => {
    if (document.visibilityState === 'visible' || !document.hidden) {
      BaseService.checkToken();
    }
  };

  const handleTokenExpired = useCallback(() => {
    handleLogout();
    notification.info({
      message: t('global:session_expired'),
    });
  }, [handleLogout, t]);

  useEffect(() => {
    window.addEventListener('onTokenExpired', handleTokenExpired);
    document.addEventListener('visibilitychange', visibilityChange);
    return () => {
      window.removeEventListener('onTokenExpired', handleTokenExpired);
      document.removeEventListener('visibilitychange', visibilityChange);
    };
  }, [handleTokenExpired]);

  const items = navigationConfig(handleMenuClick);

  const defaultSelectedKeys =
    navigationMenus.find((item) =>
      matchPath(pathname, {
        path: `${item.key}${item.key === '/' ? '' : '*'}`,
        exact: true,
      }),
    )?.key ?? '/';

  return (
    <Layout style={{ height: '100vh', alignItems: 'stretch' }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        className={'slider-override'}
        width={240}
        style={{
          display: 'flex',
        }}
      >
        <Flex
          justify={'center'}
          style={{
            padding: '20px 0',
          }}
        >
          <WhiteLogo />
        </Flex>
        <Menu
          theme={'dark'}
          selectedKeys={[`${defaultSelectedKeys}`]}
          mode="inline"
          activeKey={`${defaultSelectedKeys}`}
          items={items}
        />
        <div style={{ flexGrow: 1 }} />
        <Flex
          align={'center'}
          gap={8}
          onClick={() => history.push('/profile')}
          style={{
            paddingLeft: 32,
            paddingBottom: 8,
            cursor: 'pointer',
          }}
        >
          <Avatar
            style={{
              minWidth: 32,
              backgroundColor: token.colorPrimaryText,
            }}
            icon={<Person />}
          />
          <Typography.Text
            ellipsis
            style={{
              color: token.colorPrimaryText,
            }}
          >
            {auth?.user.firstname} {auth?.user.lastname}
          </Typography.Text>
        </Flex>
        <Button onClick={handleLogout} type={'primary'} className={'logout'}>
          {t('global:logout')}
        </Button>
      </Sider>
      <Layout>
        <Content style={{ padding: '0 16px', overflow: 'auto' }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default React.memo(AppLayout);
