import { ListRequestPayload } from 'types/Request';

let prefixer: string | undefined;

const hostname = window.location.hostname;
const port = window.location.port;

switch (hostname) {
  case process.env.REACT_APP_STAGING_HOST:
    prefixer = process.env.REACT_APP_STAGING_API_URL;
    break;
  case process.env.REACT_APP_DEV_HOST:
    prefixer = process.env.REACT_APP_DEV_API_URL;
    break;
  default:
    if (port === '3000') prefixer = process.env.REACT_APP_LOCAL_API_URL;
    else prefixer = process.env.REACT_APP_DEV_API_URL;
}

export interface GlobalPaginate {
  page?: number;
  per_page?: number;
  keyword?: string;
}

export const AuthUrls = {
  PASSWORD_LOGIN: `${prefixer}/auth/local`,
  PASSWORD_CHANGE: `${prefixer}/user/change-password`,
  RESET_PASSWORD_INIT: `${prefixer}/user/forgot-password`,
  RESET_PASSWORD: `${prefixer}/user/reset-password`,
  CREATE_PASSWORD: `${prefixer}/user/create-password`,
  REFRESH_TOKEN: `${prefixer}/auth/refresh-token`,
};

export const UserUrls = {
  GET_CONNECTED_INFO: `${prefixer}/users/me`,
  GET_ACCOUNT_INFO: `${prefixer}/user/infos`,
};

export const RequestUrls = {
  CREATE: `${prefixer}/demande/create`,
  DOWNLOAD_FILE: `${prefixer}/user/download-file`,
  MESSAGES: (caseId: string) => `${prefixer}/user/cases/${caseId}/messages`,
  SEND_MESSAGE: `${prefixer}/user/send-message`,
  LIST: (payload: ListRequestPayload) =>
    `${prefixer}/user/cases?type=${payload.type.join(',')}&status=${payload.status.join(',')}&keyword=${payload.keyword || ''}&id=${payload.id || ''}&limit=1000`,
};

export const CompteurUrls = {
  GET_DETAILS: (compteurId: string) => `${prefixer}/compteur/${compteurId}`,
  LIST: (pageSize: number, page: number, orderBy: string, order: string) =>
    `${prefixer}/compteur/list?page=${page ?? 1}&pageSize=${pageSize ?? 10}&order=${order}&orderBy=${orderBy}`,
};
